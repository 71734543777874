const Home = () => {
    return (
        <iframe
            src="https://dev.twentyfour7.com"
            width="100%"
            height="1000px"
        />
    );
};

export default Home;
